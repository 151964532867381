import React, { createContext, useContext, useState, useEffect } from 'react'
import moment from 'moment'

import api from '../utils/api'
import { AuthContext } from './auth'

const TopBarContext = createContext()

function TopBarProvider ({ children }) {
  const auth = useContext(AuthContext)
  const [showTopBar, setShowTopBar] = useState(false)
  const [messages, setMessages] = useState([])

  useEffect(() => {
    async function loadAlerts () {
      try {
        const date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        const res = await api.get(`/whitelabel/alerts?date=${date}`)
        res.data.data.alerts.length > 0 && formatMessages(res.data.data.alerts)
      } catch (e) {
      }
    }

    function formatMessages (alerts) {
      const newMessages = []
      alerts.forEach(item => {
        let tag = 'Informativo'
        if (item.kind === 'warning') {
          tag = 'Aviso'
        } else if (item.kind === 'danger') {
          tag = 'Alerta'
        }
        newMessages.push({
          message: item.message,
          tag: tag
        })
      })
      setMessages(newMessages)
      setShowTopBar(true)
    }

    auth.userInfo.isAuth && loadAlerts()
  }, [auth.userInfo.isAuth])

  return (
    <TopBarContext.Provider value={{
      messages,
      showTopBar,
      setShowTopBar
    }}
    >
      {children}
    </TopBarContext.Provider>
  )
}

export { TopBarProvider, TopBarContext }
