import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import ErrorPrevent from './ErrorPrevent'

ReactDOM.render(
  <ErrorPrevent>
    <App />
  </ErrorPrevent>,
  document.getElementById('root')
)
