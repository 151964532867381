export function bearerTokenHeader (token) {
  return {
    authorization: `Bearer ${token}`
  }
}

export function empresaToken (tokenEmpesa) {
  return {
    'token-empresa': tokenEmpesa
  }
}
