import React, { createContext, useContext, useEffect, useState } from 'react'
import { RealtimeContext } from './realtime'

const WhitelabelManutencaoContext = createContext()

function WhitelabelManutencaoProvider({ children }) {
  const { socket } = useContext(RealtimeContext)

  const [maintenance, setMaintenance] = useState(false)

  // setup inicial
  useEffect(() => {
    if (socket) {
      socket.on('whitelabel_manutencao', (data) => {
        console.log('manutenção:', data)
        const [maintenance] = JSON.parse(data)
        setMaintenance(maintenance)

        if (maintenance) {
          console.log('whitelabel em manutenção')
        } else {
          console.log('whitelabel não está mais em manutenção')
        }
      })
    }
  }, [socket])

  return (
    <WhitelabelManutencaoContext.Provider value={{ maintenance }}>
      {children}
    </WhitelabelManutencaoContext.Provider>
  )
}

export { WhitelabelManutencaoProvider, WhitelabelManutencaoContext }
