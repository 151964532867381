import styled from 'styled-components'

export const ToastContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: end;
  position: fixed;
  gap: 16px;
  top: 0;
  left: 0;
  
  height: 100vh;
  width: 100%;
  overflow: hidden;

  padding-bottom: 20px;
  padding-right: 1.5vw;

  z-index: 99999;
  pointer-events: none;
`
