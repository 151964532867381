export function saveSession ({ token, tokenEmpresa }) {
  saveToken(token)
  saveTokenEmpresa(tokenEmpresa)
}

export function saveToken (token) {
  window.localStorage.setItem('wisepay-token', token)
}

export function saveTokenEmpresa (tokenEmpresa) {
  window.localStorage.setItem('wisepay-empresa', tokenEmpresa)
}

export function removeSession () {
  window.localStorage.removeItem('wisepay-token')
  window.localStorage.removeItem('wisepay-empresa')
}

export function getSession () {
  return {
    token: window.localStorage.getItem('wisepay-token'),
    tokenEmpresa: window.localStorage.getItem('wisepay-empresa')
  }
}

export function logout ({ auth, history, invalidSession, message }) {
  auth.logout()
  history.push('/login', { invalidSession, message })
}

export function redirectToDefaultModulo ({ defaultModulo, history }) {
  history.push(defaultModulo)
}
