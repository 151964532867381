import React, { createContext, useContext, useEffect, useState } from 'react'
import ReactInterval from 'react-interval'
import { RealtimeContext } from './realtime'
import { WarningContext } from './aviso'

const AnticipationContext = createContext()

function AnticipationProvider ({ children }) {
  const { socket } = useContext(RealtimeContext)
  const { setMessage } = useContext(WarningContext)

  const [totalParcels, setTotalParcels] = useState(0)
  const [completedParcels, setCompletedParcels] = useState(0)
  const [percent, setPercent] = useState(0)
  const [timer, setTimer] = useState(0)
  const [timerEnabled, setTimerEnabled] = useState(false)
  const [error, setError] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [showAnticipationProgress, setShowAnticipationProgress] = useState(false)

  // setup inicial
  useEffect(() => {
    if (socket) {
      socket.on('antecipacao_iniciada', (data) => {
        if (data) {
          setShowAnticipationProgress(true)
          setTotalParcels(data[2])
          setCompletedParcels(0)
          setPercent(0)
          setTimer(0)
          setTimerEnabled(true)
        }
      })

      socket.on('antecipacao_parcela_atualizada', (data) => {
        if (data) {
          const total = data[2]
          const actualParcel = data[3]
          const success = data[5]
          const newPercent = data[4]

          setShowAnticipationProgress(true)
          totalParcels === 0 && setTotalParcels(total)
          setCompletedParcels(actualParcel)
          setPercent(newPercent)
          setMessage('')
          setTimer(0)
          setTimerEnabled(true)

          if (!success) {
            const formatError = []
            formatError.push(actualParcel)
            setError(formatError)
          }
        }
      })

      socket.on('antecipacao_finalizada_sucesso', (data) => {
        if (data) {
          setMessage('')
          setShowAnticipationProgress(false)
        }
      })

      socket.on('antecipacao_finalizada_erro', (data) => {
        if (data) {
          setMessage('')
          setShowAnticipationProgress(false)
          setCompletedParcels(0)
        }
      })
    }
  }, [socket])

  useEffect(() => {
    if (error.length === 1) {
      setErrorMessage('Erro na parcela ' + error[0])
    } else if (error.length > 1) {
      setErrorMessage(() => {
        let format = ''

        error.forEach((item, index) => {
          if (index === 0) {
            format = item
          } else if (error[index + 1]) {
            format = `${format}, ${item}`
          } else {
            format = `${format} e ${item}`
          }
        })

        return 'Erro nas parcelas: ' + format
      })
    }
  }, [error])

  // limpa a antecipação ao finalizar o processamento das parcelas
  useEffect(() => {
    if (!showAnticipationProgress) {
      setError('')
      setPercent(0)
      setCompletedParcels(0)
      setTotalParcels(0)
      setError([])
      setErrorMessage('')
      setTimer(0)
      setTimerEnabled(false)
    }
  }, [showAnticipationProgress])

  useEffect(() => {
    if (timer === 8) {
      setMessage('As parcelas estão demorando mais do que o normal para serem processadas, por favor aguarde.')
      setShowAnticipationProgress(false)
    }
  }, [timer])

  return (
    <AnticipationContext.Provider value={{
      completedParcels,
      errorMessage,
      percent,
      showAnticipationProgress,
      totalParcels,
      setShowAnticipationProgress
    }}
    >
      <ReactInterval
        timeout={1000}
        callback={() => setTimer(timer + 1)}
        enabled={timerEnabled}
      />
      {children}
    </AnticipationContext.Provider>
  )
}

export { AnticipationProvider, AnticipationContext }
