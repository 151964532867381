import styled, { keyframes } from 'styled-components'

export const slideEnter = keyframes`
  0% {
    opacity: 0;
    right: -300px;
  }
  100% {
    opacity: 1;
    right: 0px;
  }
`

export const slideExit = keyframes`
  0% {
    opacity: 1;
    right: 0px;
  }
  100% {
    opacity: 0;
    right: -300px;
  }
`

export const Box = styled.div`
  display: flex;
  position: relative;
  pointer-events: auto;
  width: 280px;
  height: fit-content;
  margin-bottom: 20px;

  animation: ${slideEnter} .5s ease-in-out;
  transition: right .5s;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => props.statusColor};
    border-radius: 25px;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 98%;

  background-color: var(--white);
  border-radius: 25px;
  -webkit-box-shadow: 0px 10px 30px 0px var(--shadowSecondary);
  -moz-box-shadow: 0px 10px 30px 0px var(--shadowSecondary);
  box-shadow: 0px 10px 30px 0px var(--shadowSecondary);

  padding: 24px;

  position: relative;
  left: 2.3%;
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Icon = styled.div`
  svg {
    width: 38px;
    height: 38px;
  }
`

export const Text = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;

  margin-left: 14px;
  max-width: 160px;
`

export const Close = styled.div`
  margin-top: -10px;
  margin-right: -5px;

  svg {
    width: 8px;
    cursor: pointer;
    opacity: .5;
    transition: opacity .3s;

    :hover {
      opacity: 1;
    }
  }
`
