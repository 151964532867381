export function getUrl() {
  let url
  const env = getEnv()
  if (env === "development" || env === "local") {
    url = 'https://wpdataservice-dev.wisepay.com.br/'
  } else if (window.location.href.split('.')[0].includes('-hml')) {
    url = 'https://wpdataservice-hml.wisepay.com.br/'
  } else {
    url = 'https://wpdataservice.wisepay.com.br/'
  }
  return url
}

export function getUrlNotify() {
  let url
  const env = getEnv()
  if (env === "development" || env === "local") {
    url = 'https://wpnotify-dev.wisepay.com.br/'
  } else if (window.location.href.split('.')[0].includes('-hml')) {
    url = 'https://wpnotify-hml.wisepay.com.br/'
  } else {
    url = 'https://wpnotify.wisepay.com.br/'
  }
  return url
}

export function getOrgIdAntifraud() {
  let orgId
  const env = getEnv()
  if (env === "development" || env === "local") {
    orgId = '1snn5n9w'
  } else if (window.location.href.split('.')[0].includes('-hml')) {
    orgId = '1snn5n9w'
  } else {
    orgId = 'k8vif92e'
  }
  return orgId
}

export function getEnv() {
  if (window.location.href.split('.')[0].includes('dev') || window.location.href.includes('vercel.app')) {
    return "development"
  } else if (window.location.href.includes('localhost')) {
    return "local"
  } else {
    return "production"
  }
}