import React, { useContext, Suspense } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { AuthContext } from './contexts/auth'

import Routes from './Routes/index'
import ToastContainer from './components/ui/Toast/ToastContainer'
import ToastItem from './components/ui/Toast/ToastItem'

import { storeAuth, storeRouter } from './contexts/global'

function Root () {
  const [toastData, setToastData] = React.useState(false)
  const auth = useContext(AuthContext)
  const location = useLocation()
  const history = useHistory()

  storeRouter(history)
  storeAuth(auth)

  React.useEffect(() => {
    if (location.state && location.state.invalidSession) {
      setToastData({
        message: location.state.message || null
      })
    }
  }, [location.state])

  React.useEffect(() => {
    if (toastData) {
      history.replace('', null)
    }
  }, [toastData])

  if (auth.userInfo.isAuth === null) return null

  return (
    <>
      <Suspense fallback={null}>
        <Routes isAuth={auth.userInfo.isAuth} />
      </Suspense>

      {toastData &&
        <ToastContainer>
          <ToastItem
            type='error'
            text={toastData.message || 'Sessão Expirou'}
            setToastActive={() => setToastData(null)}
            onClick={() => setToastData(null)}
          />
        </ToastContainer>}
    </>
  )
}

export default Root
