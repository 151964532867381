import api from '../utils/api'

export async function getAllEmpresas () {
  return api.get('/empresas')
}

export async function getEmpresasByMaquineta (id) {
  return api.get(`/empresas/${id}/maquinetas`)
}

export async function getEmpresasByAdministradores (id) {
  return api.get(`/empresas/${id}/administradores`)
}

export async function getEmpresasByRepresentante (id) {
  return api.get('/representate')
}

export async function getMyFees () {
  return api.get('/empresas/minhas-taxas')
}

export function valorDisponivelSaque () {
  return api.get('/empresas/valor-disponivel-saque')
}

export function companyToken () {
  return api.get('empresas/token')
}

export function getMaquinetas (qs = '') {
  return api.get(`empresas/maquinetas${qs}`)
}

export function getRepresentantes (qs = '') {
  return api.get(`representantes${qs}`)
}
