import React, { createContext, useState } from 'react'

const WarningContext = createContext()

function WarningProvider ({ children }) {
  const [message, setMessage] = useState('')

  return (
    <WarningContext.Provider value={{
      message,
      setMessage
    }}
    >
      {children}
    </WarningContext.Provider>
  )
}

export { WarningProvider, WarningContext }
