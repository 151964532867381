import React, { createContext, useContext, useEffect, useState } from 'react'
import { RealtimeContext } from './realtime'
import { AuthContext } from './auth'

const RemittanceContext = createContext()

function RemittanceProvider({ children }) {
  const { socket } = useContext(RealtimeContext)
  const { userInfo } = useContext(AuthContext)

  const whiteLabelName = JSON.parse(window.localStorage.getItem('whitelabel-theme'))?.short_name

  const [toastRemessaActive, setToastRemessaActive] = useState(false)
  const [toastRemessaData, setToastRemessaData] = useState({})

  useEffect(() => {
    if (socket) {
      socket.on('remessa_processada', (data) => {
        if (userInfo.user.id === data[0] && whiteLabelName === data[1]) {
          setToastRemessaData({
            type: 'success',
            text: 'Remessa processada com sucesso.'
          })
          setToastRemessaActive(true)
        }
      })
    }
  }, [socket])

  return (
    <RemittanceContext.Provider value={{
      toastRemessaActive,
      toastRemessaData,
      setToastRemessaActive,
      setToastRemessaData
    }}
    >
      {children}
    </RemittanceContext.Provider>
  )
}

export { RemittanceProvider, RemittanceContext }
