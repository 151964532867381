import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: #FFFFFF;

  @media (max-width: 620px) {
    flex-direction: column;
  }
`

export const Col = styled.div`
  width: ${props => props.sm ? '22%' : '30%'};
  background-color: #FFFFFF;
  padding: 0px 15px;

  @media (max-width: 1000px) {
    width: ${props => props.sm ? '30%' : '45%'};
  }

  @media (max-width: 790px) {
    width: ${props => props.sm ? '50%' : '45%'};
    ${props => {
      if (props.sm) {
        return css`
          display: flex;
          justify-content: center;
        `
      }
    }}
  }

  @media (max-width: 620px) {
    width: 100%;
    padding: 0px 25px;
    ${props => {
      if (props.sm) {
        return css`
          display: none;
        `
      }
    }}
  }
`

export const SvgBox = styled.div`
  svg {
    max-width: 100%;
    max-height: 100vh;
    transform: scale(1.03);

    path:not([data-name='Caminho 3498']) {
      fill: ${props => props.color};
    }
  }
`

export const Logo = styled.img`
  max-height: 150px;
  max-height: 74px;
  margin-bottom: 15px;
`

export const Title = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #63799B;
  margin-bottom: 15px;
  line-height: 2;
`

export const Description = styled.p`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #63799B;
  margin-bottom: 18px;
`

export const Button = styled.a`
  display: block;
  width: fit-content;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none !important;
  color: #FFFFFF !important;
  background-color: ${props => props.color};
  cursor: pointer;
  padding: 11px 27px 11px 27px;
  border: 1px solid ${props => props.color};
  border-radius: 50px;
  transition: .3s;

  &:hover {
    -webkit-box-shadow: 0px 4px 16px 0px ${props => props.color + '50'};
    -moz-box-shadow: 0px 4px 16px 0px ${props => props.color + '50'};
    box-shadow: 0px 4px 16px 0px ${props => props.color + '50'};
    opacity: .9;
  }
`
