import React, { createContext, useState } from 'react'

import { companyToken } from '../services/empresa'

const EmpresaContext = createContext()

const INITIAL_STATE = {
  empresaSelected: null
}

function EmpresaProvider({ children }) {
  const [empresaSelected, setEmpresaSelected] = useState(INITIAL_STATE.empresaSelected)
  const [reloadEmpresas, setReloadEmpresas] = useState(false)

  async function restoreEmpresaFromToken() {
    const token = window.localStorage.getItem('wisepay-empresa')
    if (token) {
      const res = await companyToken()
      setEmpresaSelected(res.data.data.empresa)
    }
  }

  return (
    <EmpresaContext.Provider value={{
      empresaSelected,
      setEmpresaSelected,
      restoreEmpresaFromToken,
      reloadEmpresas,
      setReloadEmpresas
    }}
    >
      {children}
    </EmpresaContext.Provider>
  )
}

export { EmpresaProvider, EmpresaContext }
