import { createGlobalStyle } from 'styled-components'

const primary = props => props.theme.theme_color.primary_color
const primaryDisabled = props => props.theme.theme_color.disabled_primary_color
const secondary = props => props.theme.theme_color.secondary_color
const menuPrimary = props => props.theme.theme_menu.end_color
const menuSecondary = props => props.theme.theme_menu.init_color
const menuSvg = props => props.theme.theme_menu.svg
const menuSvgActive = props => props.theme.theme_menu.active_svg
const chatButton = props => props.theme.theme_color.chat_color
const text = props => props.theme.theme_color?.text || ''
const textDark = props => props.theme.theme_color?.text_dark || ''
const scrollbar = props => props.theme.theme_color?.scroll_bar || ''
const scrollbarbg = props => props.theme.theme_color?.scroll_bg || ''
const shadowPrimaryRed = props => props.theme.theme_color?.shadow_primary_red || ''
const shadowPrimaryGreen = props => props.theme.theme_color?.shadow_primary_green || ''
const shadowPrimaryBlue = props => props.theme.theme_color?.shadow_primary_blue || ''
const shadowPrimaryAlpha = props => props.theme.theme_color?.shadow_primary_alpha || ''
const shadowSecondaryRed = props => props.theme.theme_color?.shadow_secondary_red || ''
const shadowSecondaryGreen = props => props.theme.theme_color?.shadow_secondary_green || ''
const shadowSecondaryBlue = props => props.theme.theme_color?.shadow_secondary_blue || ''
const shadowSecondaryAlpha = props => props.theme.theme_color?.shadow_secondary_alpha || ''
const shadowTertiaryRed = props => props.theme.theme_color?.shadow_tertiary_red || ''
const shadowTertiaryGreen = props => props.theme.theme_color?.shadow_tertiary_green || ''
const shadowTertiaryBlue = props => props.theme.theme_color?.shadow_tertiary_blue || ''
const shadowTertiaryAlpha = props => props.theme.theme_color?.shadow_tertiary_alpha || ''

// SETTING TITTLE
// Romaozito, depois ve um lugar melhor pra fazer isso kkk
// document.title = props.theme.name

export default createGlobalStyle`
  :root {
    --primary: ${primary};
    --primaryDisabled: ${primaryDisabled};
    --secondary: ${secondary};
    --menuPrimary: ${menuPrimary};
    --menuSecondary: ${menuSecondary};
    --menuSvg: ${menuSvg};
    --menuSvgActive: ${menuSvgActive};
    --menuLinkActive: ${primary};
    --chatButton: ${chatButton};

    --text: ${text};
    --textDark: ${textDark};
    --scrollbar: ${scrollbar};
    --scrollbarBg: ${scrollbarbg};
    --scrollbarBgFirefox: ${scrollbarbg};
    --shadowPrimary: rgba(${shadowPrimaryRed},${shadowPrimaryGreen},${shadowPrimaryBlue},${shadowPrimaryAlpha});
    --shadowSecondary: rgba(${shadowSecondaryRed},${shadowSecondaryGreen},${shadowSecondaryBlue},${shadowSecondaryAlpha});
    --shadowTertiary: rgba(${shadowTertiaryRed},${shadowTertiaryGreen},${shadowTertiaryBlue},${shadowTertiaryAlpha});
    --warning: #FFDA83;
    --warningDark: #ad881f;
    --toastSuccessPrimary: #8BFF06;
    --toastSuccessSecondary: #0381B7;
    --toastErrorPrimary: #F2453D;
    --toastErrorSecondary: #F47B0E;
    --toastWarningPrimary: #F47B0E;
    --toastWarningSecondary: #F4D50E;


    --aliceBlue: #EFF6F9;
    --aliceBlueLight: #F9FDFF;
    --blue: #0384C1;
    --blueDark: #2E4765;
    --green: #68C102;
    --greenLight: #b3e080;
    --greenDark: #526c30;
    --grey: #63799B;
    --greyLight: #97A3B5;
    --greyDark: #707070;
    --orange: #F47B0E;
    --danger: #F1443C;
    --light: #F5F6FA;
    --white: #FFF;
    --hawkesBlue: #D8DEE9;
    --darkGrey: #2E4765;
    --darkgrayish: #1D2634;
    --solitude: #eff1f6;
    --solitudeDark: #E8E9EC;
    --prim: #D3DDEA;
    --licorice: #2B3441;
    --silver: #BFBFBF;
    --wedgewood: #52667F;
    --shipCove: #A7B5CB;
    --cadetBlue: #BCC0CB;
    --lavender: #E5E5EA;
    --geyser: #cfd6e1;
    --mystic: #ccd1d8;
    --luminous: #f4c60e;
    --blueTurq: #55D8FE;
    --dangerLight: #FF8373;
    --yellow: #FFDA83;
    --yellowDark: #ad881f;
    --yellowLight: #ffedb4;
    --purple: #A3A0FB;
    --greenStrong: #ABFE75;
    --greenBlue: #54F9B1;
    --pink: #F683FF;
    --orangeLight: #FBD3A5;
    --blueCard: #2A98FF;
    --topBar: #dfe6e9;
  }
`
