let _auth = null

let _router = null

let _defaultModulo = null

export function storeRouter (router) {
  _router = router
}

export function routerPush (to, state) {
  if (_router) {
    _router.push(to, state)
  }
}

export function storeAuth (auth) {
  _auth = auth
}

export function storeDefaultModulo (defaultModulo) {
  _defaultModulo = defaultModulo
}

export function getAuth () {
  return _auth
}

export function getRouter () {
  return _router
}

export function getDefaultModulo () {
  return _defaultModulo
}
