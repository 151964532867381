import axios from 'axios'

import { saveToken } from './session'
import { decode, hasExpired, remainingDurationMinutes } from './token'

import { getUrl } from '../utils/config'
import { bearerTokenHeader } from '../utils/authorizationHeader'

const MIN_MINUTES_TO_REFRESH = 45

export function refreshToken () {
  let running = false

  return async function (oldToken) {
    if (running) {
      return oldToken
    }

    running = true

    const decoded = decode(oldToken)
    if (hasExpired(decoded)) {
      running = false
      return null
    }

    const remaining = remainingDurationMinutes(decoded)
    if (remaining <= MIN_MINUTES_TO_REFRESH) {
      const newToken = await requestRefreshToken(oldToken)
      if (newToken) {
        saveToken(newToken)
        running = false
        return newToken
      }
    }

    running = false
    return oldToken
  }
}

export async function requestRefreshToken (oldToken) {
  try {
    const res = await axios.get(`${getUrl()}users/refresh-token`, {
      headers: bearerTokenHeader(oldToken)
    })
    return res.data.data.token
  } catch (e) {
    return null
  }
}
