import { createContext, useState, useEffect, useContext } from 'react'

import { AuthContext } from './auth'

import api from '../utils/api'

const PermissionContext = createContext()

function PermissionProvider({ children }) {
  const { userInfo } = useContext(AuthContext)

  const [permissions, setPermissions] = useState([])
  const [permissionsLoaded, setPermissionsLoaded] = useState(false)

  useEffect(() => {
    async function getPermissions() {
      try {
        const { data: response } = await api.get(`/permissoes/user/${userInfo.user.id}`)

        const actions = response.data.permissoes.map(permission => permission.action)

        setPermissions(actions)
        setPermissionsLoaded(true)
      } catch (error) {
        //
      }
    }

    userInfo?.user?.root && setPermissionsLoaded(true)
    !userInfo?.user?.root && userInfo?.user?.id && getPermissions()
  }, [userInfo?.user?.id, userInfo?.user?.root])

  function checkPermission(actions) {
    if (userInfo.user.root) return true

    if (typeof actions === 'string') {
      return permissions.includes(actions)
    } else if (Array.isArray(actions)) {
      return actions.some(item => permissions.includes(item))
    }

    return false
  }

  return (
    <PermissionContext.Provider
      value={{
        permissions,
        checkPermission,
        permissionsLoaded,
      }}
    >
      {children}
    </PermissionContext.Provider>
  )
}

export { PermissionProvider, PermissionContext }
