import React, { lazy } from 'react'

const AppRoutes = lazy(() => import('./AppRoutes'))
const PublicRoutes = lazy(() => import('./PublicRoutes'))

function Routes ({ isAuth }) {
  return isAuth ? <AppRoutes /> : <PublicRoutes />
}

export default Routes
