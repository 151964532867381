import jwtDecode from 'jwt-decode'

export function decode (token) {
  return jwtDecode(token)
}

/**
 * Retorna a quantidade de minutoes restantes para
 * a expiração do token.
 * @param {Object} decoded
 * @returns {Number}
 */
export function remainingDurationMinutes (decoded) {
  const expInMilliseconds = decoded.exp * 1000
  const diffDate = new Date(
    new Date(expInMilliseconds) - new Date()
  )
  return diffDate.getMinutes()
}

export function hasExpired (decoded) {
  if (!decoded.exp) return false
  return decoded.exp < new Date().getTime() / 1000
}
