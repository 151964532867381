import React from 'react'
import { ThemeContext } from '../../../contexts/theme'

import ColorStyles from '../../../ColorStyles'

export default function Colors (props) {
  const themeContext = React.useContext(ThemeContext)

  if (themeContext === null) {
    return null
  }

  return (
    <ColorStyles theme={themeContext} />
  )
}
