import { useContext, useEffect, useState, createContext } from 'react'
import { useLocation } from 'react-router-dom'

import api from '../utils/api'
import { storeDefaultModulo } from './global'
import { AuthContext } from './auth'
import { EmpresaContext } from './empresa'
import { ThemeContext } from './theme'

const ModulosContext = createContext()

function ModulosProvider({ children }) {
  const location = useLocation()
  const auth = useContext(AuthContext)
  const { empresaSelected } = useContext(EmpresaContext)
  const { cercActive, phoebusActive } = useContext(ThemeContext)

  const [modulos, setModulos] = useState([])
  const [defaultModulo, setDefaultModulo] = useState(null)

  useEffect(() => {
    checkToLoadModulos()
  }, [location.pathname, auth.userInfo.isAuth])

  function checkToLoadModulos() {
    if (location.pathname !== '/login' && auth.userInfo.isAuth) {
      loadModulos()
    }
  }

  function removeModuleByKey(modulos, key) {
    modulos = modulos.filter(modulo => !modulo.nome.toLocaleLowerCase().includes(key))

    modulos = modulos.reduce((items, modulo) => {
      modulo.modulosChildren = modulo.modulosChildren.filter(mdlChildren => !(mdlChildren.nome.toLocaleLowerCase().includes(key) || mdlChildren.path.includes(key)))

      items.push(modulo)
      return items
    }, [])

    return modulos
  }

  async function loadModulos() {
    try {
      const result = await api.get('modulos/access')
      let modulos = result.data.data.modulos
      let moduloD = ''

      if (!cercActive) {
        modulos = removeModuleByKey(modulos, 'cerc')
      }

      if (!phoebusActive) {
        modulos = removeModuleByKey(modulos, 'phoebus')
      }

      if (empresaSelected.root && auth.userInfo.user.root) {
        moduloD = '/dashboard'
      } else {
        modulos.forEach(modulo => {
          if (modulo.modulosChildren.length === 0) {
            if (moduloD === '' && modulo.path) {
              moduloD = modulo.path
            }
          } else {
            modulo.modulosChildren.forEach(mc => {
              if (mc.path === '/extrato/transacoes') {
                moduloD = '/extrato/transacoes'
              } else if (moduloD === '' && mc.path) {
                moduloD = mc.path
              }
            })
          }
        })
      }

      setModulos(modulos)
      setDefaultModulo(moduloD)
      storeDefaultModulo(moduloD)
    } catch (error) {
      // show error
    }
  }

  return (
    <ModulosContext.Provider
      value={{
        modulos,
        defaultModulo
      }}
    >
      {children}
    </ModulosContext.Provider>
  )
}

export { ModulosProvider, ModulosContext }
