import React, { useState, createContext, useEffect, useContext } from 'react'

import api from '../utils/api'
import { formatBR } from '../utils/money'
import { getSaldos } from '../services/saldo'
import { RealtimeContext } from './realtime'

const INITIAL_STATE = {
  saldo: null,
  loading: false,
  error: null
}

const SaldoContext = createContext()

function SaldoProvider({ children }) {
  const { socket } = useContext(RealtimeContext)

  const [loading, setLoading] = useState(INITIAL_STATE.loading)
  const [saldo, setSaldo] = useState(INITIAL_STATE.saldo)
  const [error, setError] = useState(INITIAL_STATE.error)

  useEffect(() => {
    if (socket) {
      socket.on('saldo_atualizado', () => {
        if (!loading) {
          loadSaldos()
        }
      })
    }
  }, [socket])

  async function viewBalances() {
    const { data: response } = await api.get('modulos/access')
    const view_balances = response.data.view_balances
    !view_balances && setSaldo(null)

    return view_balances
  }

  async function loadSaldos() {
    setLoading(true)
    try {
      if (await viewBalances()) {
        const result = await getSaldos()
        const { saldos } = result.data.data

        if (saldos) {
          setSaldo({
            valorDisponivel: formatBR(saldos.valor_disponivel_centavos / 100),
            valorReceber: formatBR(saldos.valor_receber_centavos / 100),
            valorBloqueado: formatBR(saldos.valor_bloqueado_centavos / 100),
            valorRetido: formatBR(saldos.valor_retido_centavos / 100),
            valorDisponivelCentavos: saldos.valor_disponivel_centavos,
            valorReceberCentavos: saldos.valor_receber_centavos,
            valorBloqueadoCentavos: saldos.valor_bloqueado_centavos,
            valorRetidoCentavos: saldos.valor_retido_centavos
          })
        } else {
          setSaldo(null)
        }
      }
    } catch (error) {
      setError(error?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <SaldoContext.Provider
      value={{
        loading,
        saldo,
        error,
        loadSaldos
      }}
    >
      {children}
    </SaldoContext.Provider>
  )
}

export { SaldoProvider, SaldoContext }
