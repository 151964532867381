import GlobalStyles from '../../GlobalStyles'

import * as S from './styles'

import { ReactComponent as Svg } from '../../assets/img/error.svg'

export default function Error() {
  let theme = {
    theme_color: {
      secondary_color: '#1DA5EC'
    }
  }

  if (window.localStorage.getItem('whitelabel-theme')) {
    theme = JSON.parse(window.localStorage.getItem('whitelabel-theme'))
    setFavicon(theme.favicon)
  }

  function setFavicon(favicon) {
    window.document.querySelector("link[rel*='icon']").setAttribute('href', favicon)
  }

  return (
    <>
      <link href='https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap' rel='stylesheet' />
      <GlobalStyles />
      <S.Container>
        <S.Col sm>
          <S.SvgBox color={theme.theme_color.secondary_color}>
            <Svg />
          </S.SvgBox>
        </S.Col>
        <S.Col>
          {theme && <S.Logo src={theme.theme_logo.logo_color} alt='' />}
          <S.Title>Não conseguimos encontrar a página que você estava procurando.</S.Title>
          <S.Description>Mas não entre em pânico! Estamos trabalhando nisso.</S.Description>
          <S.Button color={theme.theme_color.secondary_color} href='/'>Ir para o início</S.Button>
        </S.Col>
      </S.Container>
    </>
  )
}
