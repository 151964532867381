import { io } from 'socket.io-client'

import React, { useState, createContext, useEffect, useContext } from 'react'
import { EmpresaContext } from './empresa'

const RealtimeContext = createContext()

function RealtimeProvider ({ children }) {
  const [socket, setSocket] = useState(null)
  const { empresaSelected } = useContext(EmpresaContext)

  useEffect(() => {
    if (empresaSelected) {
      const token = window.localStorage.getItem('wisepay-token')
      let url = 'https://rts.wisepay.com.br/'
      if (window.location.href.split('.')[0].includes('dev') || window.location.href.includes('vercel.app') || window.location.href.includes('localhost')) {
        url = 'https://rts-dev.wisepay.com.br/'
      } else if (window.location.href.split('.')[0].includes('-hml')) {
        url = 'https://rts-hml.wisepay.com.br/'
      }

      setSocket(io(url, {
        extraHeaders: {
          authorization: token,
          empresa: empresaSelected.id
        }
      }))
    }
  }, [empresaSelected])

  return (
    <RealtimeContext.Provider
      value={{ socket }}
    >
      {children}
    </RealtimeContext.Provider>
  )
}

export { RealtimeProvider, RealtimeContext }
