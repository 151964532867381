import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import Root from './Root'
import { AnticipationProvider } from './contexts/antecipacao'
import { RemittanceProvider } from './contexts/remessa'
import { AuthProvider } from './contexts/auth'
import { EmpresaProvider } from './contexts/empresa'
import { ThemeProvider } from './contexts/theme'
import { SaldoProvider } from './contexts/saldo'
import { RealtimeProvider } from './contexts/realtime'
import { WarningProvider } from './contexts/aviso'
import { TopBarProvider } from './contexts/topBar'
import { ModulosProvider } from './contexts/modulos'
import { PermissionProvider } from './contexts/permissoes'

import GlobalStyles from './GlobalStyles'
import Colors from './components/shared/Colors'
import { WhitelabelManutencaoProvider } from './contexts/whitelabelManutencao'
import { datadogRum } from '@datadog/browser-rum'
import { getEnv } from './utils/config'

function App() {
  datadogRum.init({
    applicationId: 'cd79bb06-2f29-4eec-a996-50b1ad1ec30b',
    clientToken: 'pub4d8070a63d35305d0d328c907c522471',
    site: 'datadoghq.com',
    service: 'wp-webapp',
    allowedTracingUrls: ["https://wpdataservice.wisepay.com.br",
      "https://wpdataservice-dev.wisepay.com.br",
      "https://wpnotify.wisepay.com.br",
      "https://wpnotify-dev.wisepay.com.br"],
    env: getEnv(),
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })
  datadogRum.startSessionReplayRecording()

  return (
    <BrowserRouter>
      <ThemeProvider>
        <EmpresaProvider>

          <AuthProvider>
            <PermissionProvider>
              <TopBarProvider>
                <ModulosProvider>
                  <RealtimeProvider>
                    <WarningProvider>
                      <SaldoProvider>
                        <WhitelabelManutencaoProvider>
                          <RemittanceProvider>
                            <AnticipationProvider>
                              <Root />
                            </AnticipationProvider>
                          </RemittanceProvider>
                        </WhitelabelManutencaoProvider>
                      </SaldoProvider>
                    </WarningProvider>
                  </RealtimeProvider>
                </ModulosProvider>
              </TopBarProvider>
            </PermissionProvider>
          </AuthProvider>
        </EmpresaProvider>
        <GlobalStyles />
        <Colors />
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
