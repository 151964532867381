import { createContext, useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../utils/api'
import { EmpresaContext } from './empresa'
import { datadogRum } from '@datadog/browser-rum'

const AuthContext = createContext()

const INITIAL_STATE = {
  isAuth: null,
  user: {
    email: '',
    nome: '',
    rep: false,
    root: false
  }
}

function AuthProvider({ children }) {
  const history = useHistory()
  const [userInfo, setUserInfo] = useState({
    ...INITIAL_STATE
  })
  const [logoutRedirect, setLogoutRedirect] = useState(null)
  const [loggedInUser, setLoggedInUser] = useState(false)
  const empresaContext = useContext(EmpresaContext)

  async function checkUser() {
    let newUserInfo
    const token = window.localStorage.getItem('wisepay-token')

    if (token) {
      try {
        const { data: res } = await me()
        if (!empresaContext.empresaSelected) {
          await empresaContext.restoreEmpresaFromToken()
        }

        newUserInfo = {
          ...INITIAL_STATE,
          isAuth: true,
          user: {
            id: res.data.userMe.currentUser.id,
            email: res.data.userMe.currentUser.email,
            nome: res.data.userMe.currentUser.nome,
            rep: res.data.userMe.currentUser.representante,
            root: res.data.userMe.currentUser.root,
            admin: res.data.userMe.currentUser.isAdmin,
            two_factor_enable: res.data.userMe.currentUser.two_factor_enable,
            managerUser: res.data.userMe.currentUser.gestor
          },
          userPermissions: res.data.userMe.userPermissions
        }

        datadogRum.setUser({
          id: res.data.userMe.currentUser.id,
          email: res.data.userMe.currentUser.email,
          nome: res.data.userMe.currentUser.nome,
          rep: res.data.userMe.currentUser.representante,
          root: res.data.userMe.currentUser.root,
          admin: res.data.userMe.currentUser.isAdmin,
          two_factor_enable: res.data.userMe.currentUser.two_factor_enable,
          managerUser: res.data.userMe.currentUser.gestor
        })
      } catch (e) {
        // show error
      }
    } else {
      newUserInfo = {
        ...userInfo,
        isAuth: false
      }
    }
    setUserInfo({ ...newUserInfo })
  }

  useEffect(() => {
    checkUser()
  }, [])

  // Atualizar dados do usuário após o login
  // Atualizar permissões do usuário
  useEffect(() => {
    if (userInfo.isAuth === true && !loggedInUser) {
      setLoggedInUser(true)
      checkUser()
    }
  }, [userInfo.isAuth])

  useEffect(() => {
    // === false para evitar entrar quando for null
    if (userInfo.isAuth === false && logoutRedirect) {
      history.push(logoutRedirect)
      setLogoutRedirect(null)
    }
  }, [userInfo.isAuth, logoutRedirect])

  async function me() {
    return api.get('/users/me')
  }

  async function login(email, senha) {
    return api.post('login', {
      email,
      password: senha
    })
  }

  function logout(options = {}) {
    if (options.redirect) {
      setLogoutRedirect(options.redirect)
    }
    setLoggedInUser(false)
    setUserInfo({ ...INITIAL_STATE, isAuth: false })
    window.localStorage.removeItem('wisepay-token')
    window.localStorage.removeItem('wisepay-empresa')
  }

  async function emailRecover(email) {
    return api.post('login/password-recover', {
      email
    })
  }

  async function newPassword(token, senha, confirmacaoSenha) {
    return api.put(`login/update-password/${token}`, {
      password: senha,
      password_confirmation: confirmacaoSenha
    })
  }

  return (
    <AuthContext.Provider value={{
      login,
      logout,
      emailRecover,
      newPassword,
      userInfo,
      setUserInfo,
      INITIAL_STATE
    }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }
