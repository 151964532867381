import { useEffect } from 'react'

import * as S from './styles'

import { ReactComponent as Success } from '../../../../assets/icons/toast/balao-sucesso.svg'
import { ReactComponent as Error } from '../../../../assets/icons/toast/balao-erro.svg'
import { ReactComponent as Warning } from '../../../../assets/icons/toast/balao-atencao.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/toast/fechar-toast.svg'

export default function ToastItem(props) {
  const timeout = props.timeout || 10000
  let icon = <Success />
  let statusColor = 'linear-gradient(160deg, var(--toastSuccessPrimary) 0%, var(--toastSuccessSecondary) 100%)'

  if (props.type === 'error') {
    statusColor = 'linear-gradient(160deg, var(--toastErrorPrimary) 0%, var(--toastErrorSecondary) 100%)'
    icon = <Error />
  }

  if (props.type === 'warning') {
    statusColor = 'linear-gradient(160deg, var(--toastWarningPrimary) 0%, var(--toastWarningSecondary) 100%)'
    icon = <Warning />
  }

  useEffect(() => {
    setTimeout(() => { props.setToastActive(false) }, timeout)
  }, [])

  return (
    <S.Box statusColor={statusColor} className='toastItem'>
      <S.Content>
        <S.FlexContainer>
          <S.Icon>
            {icon}
          </S.Icon>
          <S.Text>{props.text}</S.Text>
        </S.FlexContainer>

        <S.Close>
          <CloseIcon onClick={props.onClick} />
        </S.Close>
      </S.Content>
    </S.Box>
  )
}
